














































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, ValidationRule } from 'vuelidate/lib/validators'
import { phoneNumber } from '@/app/infrastructures/misc/ValidationRules'
import Button from '@/app/ui/components/Button/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import controller from '@/app/ui/controllers/MerchantController'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import MerchantTextInput from '../../components/MerchantTextInput/index.vue'
import MerchantDropdown from '../../components/MerchantDropdown/index.vue'
import { MerchantDetail } from '@/domain/entities/Merchant'

interface Form {
  account: {
    name: string
    email: string
    phone: string
  }
}

interface ValidationsInterface {
  form: {
    account: {
      name: {
        required: () => ValidationRule
      },
      phone: {
        required: () => ValidationRule
        phoneNumber: ValidationRule
      }
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    MerchantTextInput,
    MerchantDropdown,
    Button,
    FileInput,
    Modal,
    LoadingOverlay,
  },
})
export default class UpdateMerchant extends Vue {
  controller = controller
  form: Form = {
    account: {
      name: '',
      email: '',
      phone: '',
    },
  }
  confirmationModal = false
  successModal = false

  created(): void {
    controller.getMerchantDetail(this.$route.params.id)
  }

  @Watch('controller.statusUpdateMerchantAccount')
  onStatusUpdateMerchantAccountChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.UPDATE_MERCHANT_ACCOUNT_SUCCESS) {
      this.confirmationModal = false
      this.successModal = true
    }
    controller.setStatusUpdateMerchantAccount('')
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        account: {
          name: {
            required,
          },
          phone: {
            required,
            phoneNumber,
          },
        },
      },
    }
  }

  private updateMerchant(): void {
    controller.updateMerchantAccount({
      id: Number(this.$route.params.id),
      ...this.form.account,
    })
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({
      name: 'MerchantDetailAccountPage',
      params: this.$route.params,
    })
  }

  @Watch('controller.merchantDetail')
  onMerchantDetailChanged(val: MerchantDetail): void {
    this.form = {
      account: {
        name: <string>val.accountName,
        email: <string>val.accountEmail,
        phone: <string>val.accountPhone,
      },
    }
  }

  @Watch('form.account.phone')
  onPhoneNumberChanged(val: string): void {
    if (val.startsWith('08') || val.startsWith('62')) {
      this.form.account.phone = Utils.countryIndonesiaPhoneNumber(val)
    }
  }

  beforeDestroy(): void {
    controller.setStatusUpdateMerchantAccount('')
  }
}
